export const getImageUrl = (url) => {
  let baseURL =  process.env.REACT_APP_BACKEND_LIVE_API;
  let updateUrl = `${baseURL}${url}`;
  return updateUrl
};

export class CommonEnums {
  static localStorage = {
    deposit_amount: "deposit_amount",
  };
}