export class CommonEnums {
  static true = "true";
  static false = "false";

  static gameTypes = {
    classic: "classic",
    popular: "popular",
    invalid: "invalid_code",
  };

  static routeParams = {
    classic: "classic",
    popular: "popular",
    game_type: "game_type",
    Game: "Game",
    ludo_classics: "Ludo Classics",
    ludo_popular: "Ludo Popular",
  };

  static adminCommissionPercents = 5;

  static maxUploadSize = 2.5;
}
