import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ManageWebAppApiServices } from "../../../utils/api/ManageWebAppApiServices";
import "./GameMessage.scss";

const DepositMessage = () => {
  const [state, setState] = useState({
    isLoading: false,
    warningMessage: "",
    switch: false,
  });

  const getMessageApi = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    const res = await ManageWebAppApiServices?.getWebAppSettings();

    console.log("state-res>>>", res);
    if (res?.data?.success) {
      setState({
        ...state,
        warningMessage: res?.data?.data?.deposit?.metadata?.message,
        isLoading: false,
        switch: res?.data?.data?.deposit?.enable,
      });
    } else {
      // alert("Internal server error!");
      toast.error("Internal server error!");
    }
  };

  useEffect(() => {
    getMessageApi();
  }, []);
  return (
    <>
      {state?.warningMessage ? (
        <div className="collapseCard-container game_message_main_container mt-4">
          {state?.isLoading ? (
            <p className="loading">Warning message feching...</p>
          ) : (
            <p>{state?.warningMessage}</p>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default DepositMessage;
