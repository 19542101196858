import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { ManageWebAppApiServices } from "../../../utils/api/ManageWebAppApiServices";
import { CommonEnums, getImageUrl } from "../../../utils/common.utils";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Swal from "sweetalert2";

import "./ManualPaymentSetting.scss";

const ManualPaymentSetting = () => {
  const [state, setState] = useState({
    isLoading: false,
    isLoadingGetData: false,
    transactionId: "",
    transactionAmount: "",
    transactionScreenshot: null,
    paymentSettingsData: null,
  });

  console.log('state--', state.transactionAmount);

  const transactionIDHandler = (e) => {
    setState({
      ...state,
      transactionId: e?.target?.value,
    });
  };

  const transactionAmountHandler = (e) => {
    setState({
      ...state,
      transactionAmount: e?.target?.value,
    });
  };

  const screenshotHandler = (e) => {
    if (e?.target?.files.length > 0) {
      setState({
        ...state,
        transactionScreenshot: e?.target?.files[0],
      });
    }
  };

  const manualPaymentSave = async () => {
    setState({
      ...state,
      isLoading: true,
    });

    let user_id = localStorage.getItem("user_id");
    let formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("amount", state?.transactionAmount);
    formData.append("transaction_id", state?.transactionId);

    if (state?.transactionScreenshot !== null) {
      formData.append("payment_screenshot", state?.transactionScreenshot);
    }

    const res = await ManageWebAppApiServices?.addPayment(formData);

    console.log("state-res>>>", res);
    if (res?.data?.success) {
      // // toast.success("Payment details are added successfully!");
      // let successfully_message_id = document.getElementById(
      //   "successfully_message_id"
      // );
      // successfully_message_id.classList.remove("display_none");

      // setTimeout(() => {
      //   successfully_message_id.classList.add("display_none");
      // }, 5000);

      Swal.fire({
        icon: "success",
        title: "Payment details are added successfully!",
        // text: state?.settingData?.deposit?.message,
      });
    } else {
      toast.error("Internal server error!");
    }
    setState({
      ...state,
      isLoading: false,
      transactionId: "",
      transactionScreenshot: null,
      transactionAmount: "",
    });
  };

  const onClickSubmitHandler = () => {
    if (state?.transactionId && state?.transactionAmount) {
      manualPaymentSave();
    } else {
      let transactionId = document.getElementById("transaction_error_para_id");
      let transactionAmount = document.getElementById(
        "transaction_amount_error_para_id"
      );

      if (!state?.transactionId) {
        transactionId.classList.add("error_para_opacity");
        setTimeout(() => {
          transactionId.classList.remove("error_para_opacity");
        }, 3000);
      }

      if (!state?.transactionAmount) {
        transactionAmount.classList.add("error_para_opacity");
        setTimeout(() => {
          transactionAmount.classList.remove("error_para_opacity");
        }, 3000);
      }
    }
  };

  const getApiData = async () => {
    setState({
      ...state,
      isLoadingGetData: true,
    });

    const res = await ManageWebAppApiServices?.getWebAppSettings();

    const depositAmount = localStorage.getItem(
      CommonEnums.localStorage.deposit_amount
    );
    console.log("res>>", depositAmount);
    console.log("res>>", res);
    if (res?.data?.success) {
      setState({
        ...state,
        isLoadingGetData: false,
        paymentSettingsData: res?.data?.data?.payment_settings?.metadata,
        transactionAmount: depositAmount ?? 0,
      });
    } else {
      toast.error("Internal server error!");
    }
  };

  const onClickCopyId = (uiiId) => {
    navigator.clipboard.writeText(uiiId);
    let content_copy_icon_id = document.getElementById("content_copy_icon_id");
    let check_circle_icon_id = document.getElementById("check_circle_icon_id");
    content_copy_icon_id.classList.add("display_none");
    check_circle_icon_id.classList.remove("display_none");
    setTimeout(() => {
      content_copy_icon_id.classList.remove("display_none");
      check_circle_icon_id.classList.add("display_none");
    }, 5000);
  };

  useEffect(() => {
    getApiData();
  }, []);
  return (
    <div className="manual_payment_setting">
      <div className="leftContainer">
        {state?.isLoadingGetData ? (
          <div className="loading_container">
            <CircularProgress thickness={3} style={{ width: 30, height: 30 }} />
            <p>Fetching data...</p>
          </div>
        ) : (
          <div
            className="main-area body_container"
            style={{ paddingTop: "60px" }}
          >
            <div className="paymnet_container">
              <div className="top_heading">
                <p>Manual payment</p>
              </div>
              <div className="qr_code">
                <img
                  src={getImageUrl(state?.paymentSettingsData?.qr_image)}
                  alt="qr code"
                />
              </div>

              <div className="upi_id">
                <p>
                  UPI ID: <span>{state?.paymentSettingsData?.upi_id}</span>
                </p>
                <div
                  className="copy_icon"
                  // onClick={() => navigator.clipboard.writeText("hello")}
                  onClick={() =>
                    onClickCopyId(state?.paymentSettingsData?.upi_id)
                  }
                >
                  <ContentCopyIcon
                    className="copy_icon"
                    id="content_copy_icon_id"
                  />
                  <CheckCircleIcon
                    className="check_icon display_none"
                    id="check_circle_icon_id"
                  />
                </div>
              </div>

              <div className="para_contain">
                <p>Please scan QR code and pay your deposit amount</p>
                <p>After deposit paymnet upload the receipt here</p>
              </div>
            </div>

            {/* <div
              className={`successfully_message display_none`}
              id="successfully_message_id"
            >
              <p>Payment details are added successfully!</p>
            </div> */}
            <div className="paymnet_detail_form_container">
              <div className="number_input_container">
                <label>UTR*</label>
                <input
                  className="number_input border"
                  placeholder="Enter your transaction id"
                  value={state?.transactionId}
                  onChange={transactionIDHandler}
                  name="commission"
                  type="text"
                ></input>
                <p className="error_para" id="transaction_error_para_id">
                  Transaction id is required!
                </p>
              </div>

              <div className="number_input_container">
                <label>Deposit Amount*</label>
                <input
                  disabled
                  className="number_input border"
                  placeholder="Enter your deposit amount"
                  value={state?.transactionAmount}
                  onChange={transactionAmountHandler}
                  name="commission"
                  type="text"
                ></input>
                <p className="error_para" id="transaction_amount_error_para_id">
                  Deposit amount is required!
                </p>
              </div>

              {/* <div className="number_input_container file_input">
                <label>Paymnet Screenshot </label>
                <input
                  className="number_input border"
                  placeholder="Enter your commission"
                  onChange={screenshotHandler}
                  name="commission"
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                ></input>
              </div> */}

              <button onClick={onClickSubmitHandler} type="submit">
                {state.isLoading ? (
                  <CircularProgress
                    thickness={4}
                    style={{ width: 25, height: 25 }}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ManualPaymentSetting;
