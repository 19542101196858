import randomstring from "randomstring";

const battleData = {
  _id: "65fe9127243d207b2237c051",
  Table_id: null,
  Game_type: "Ludo Classics",
  Game_Ammount: 50,
  Room_code: null,
  Created_by: {
    _id: "65fd69342724e1a67cb9e39f",
    Name: "ZSaVU",
    Phone: 7434004386,
  },
  battle_type: "demo",
  //   Accepetd_By: {
  //     Name: "rHxGo",
  //     Phone: 7069993460,
  //     avatar: "public/profilepic/1711181864612-923232230.webp",
  //     _id: "65fe8faf7f20512aaf7c5363",
  //   },
  Accepetd_At: null,
  action_by: null,
  actionby_Date: null,
  Status: "new",
  Status_Update_By: null,
  Status_Reason: null,
  Creator_Status: null,
  Creator_Status_Reason: null,
  Creator_Screenshot: null,
  Creator_Status_Updated_at: null,
  Acceptor_status: null,
  Acceptor_status_reason: null,
  Acceptor_screenshot: null,
  Acceptor_status_Updated_at: null,
  Acceptor_by_Creator_at: null,
  Acceptor_seen: false,
  Room_join: false,
  Room_Status: "active",
  Winner_closingbalance: null,
  Loser_closingbalance: null,
  creatorWithdrawDeducted: null,
  acceptorWithdrawDeducted: null,
  winnAmount: null,
  room_Code_shared: "2024-03-23T08:21:59.143Z",
  createdAt: new Date()?.toString(),
  updatedAt: new Date()?.toString(),
  __v: 0,
};

const getGameAmmount = () => {
  // const demo_user_amount_limit = process.env.REACT_APP_DEMO_USER_AMOUNT_LIMIT;
  const demo_user_amount_limit = 5000;
  let max = demo_user_amount_limit / 50;
  let min = 1;
  let count = Math.floor(Math.random() * (max - min + 1) + min);
  let generateAmount = 50 * count;
  return generateAmount;
};

export const OpenBattlesInitialData = () => {
  let max = 3;
  let min = 1;
  let count = Math.floor(Math.random() * (max - min + 1) + min);

  const randome_array = [];
  for (let i = 0; i < count; i++) {
    randome_array[i] = {};
  }

  let battlesUpdate = randome_array?.map(() => {
    let randomeNumber = randomstring.generate({
      length: 5,
      charset: "alphabetic",
    });
    return {
      ...battleData,
      _id: `65fe9127243d207b223${randomeNumber}`,
      Game_Ammount: getGameAmmount() || 50,
      Created_by: {
        _id: `65fd69342724e1a67cb${randomeNumber}`,
        Name: randomeNumber,
        Phone: 1234567890,
      },
    };
  });
  return battlesUpdate;
};

export const OpenBattlesGenerate = () => {
  let max = 3;
  let min = 0;
  let count = Math.floor(Math.random() * (max - min + 1) + min);

  const randome_array = [];
  for (let i = 0; i < count; i++) {
    randome_array[i] = {};
  }

  let battlesUpdate = randome_array?.map(() => {
    let randomeNumber = randomstring.generate({
      length: 5,
      charset: "alphabetic",
    });
    return {
      ...battleData,
      _id: `65fe9127243d207b223${randomeNumber}`,
      Game_Ammount: getGameAmmount() || 100,
      Created_by: {
        _id: `65fd69342724e1a67cb${randomeNumber}`,
        Name: randomeNumber,
        Phone: 1234567890,
      },
    };
  });
  return battlesUpdate;
};
